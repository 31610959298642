<template>
  <div>
    <el-card>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: '/policylist?page=' + this.$route.query.page }"
          >申报设置</el-breadcrumb-item
        >
        <el-breadcrumb-item>编辑政策</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form ref="form" :model="form" label-width="140px" class="el-form">
        <el-form-item label="政策标题">
          <el-input v-model="form.title" placement="请输入政策标题"></el-input>
        </el-form-item>
        <el-form-item label="政策内容">
          <editor ref="editor" />
        </el-form-item>
        <el-form-item label="可申报的时间范围" style="text-align: left;">
          <div>
            <el-date-picker
              v-model="SeTime"
              type="datetimerange"
              align="right"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div
            style="margin-top:10px;"
            v-for="(item, key) in durations"
            :key="key"
          >
            <el-date-picker
              v-model="item.SeTime"
              type="datetimerange"
              align="right"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00']"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="创建时间" style="text-align: left;">
          <span
            style=" border: 1px #e4e4e4 solid;width: 200px; height: 40px;border-radius: 5px;padding: 0 0 0 15px;display: block;"
            >{{ form.createTime | formatDate }}</span
          >
        </el-form-item>
      </el-form>
      <el-row>
        <el-col>
          <el-button type="primary" @click="requestEdit" class="text mr-4"
            >确定</el-button
          >
          <el-button @click="requestCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import editor from "../../../components/editor";

export default {
  data() {
    return {
      SeTime: "",
      lgtj: false,
      timeerr: false,
      form: {
        columnId: "",
        startTime: "",
        expireTime: "",
        title: "",
        content: "",
        id: "",
      },
      id: "",
      expireTimeOption: {
        disabledDate(date) {
          // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      durations: [],
      timeId: "",
    };
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    getData() {
      this.$axios
        .get("/policy/?id=" + this.$route.query.id)
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response.data.data);
            this.$refs.editor.myValue = response.data.data.clob.content;
            this.form.title = response.data.data.title;
            this.form.content = response.data.data.clob.content;
            this.form.id = response.data.data.id;
            this.form.startTime = response.data.data.startTime;
            this.form.expireTime = response.data.data.expireTime;
            this.form.createTime = response.data.data.createTime;
            this.SeTime = [this.form.startTime, this.form.expireTime];
            response.data.data.durations.forEach((item) => {
              this.durations.push({
                ...item,
                SeTime: [item.startTime, item.endTime],
              });
            });
          }
        });
    },

    requestEdit() {
      if (this.lgtj) return;
      this.lgtj = true;
      setTimeout(() => {
        this.lgtj = false;
      }, 2000);
      this.timeerr = false;
      if (!this.SeTime) {
        this.$root.warn("政策起止时间不能为空！", "");
        return;
      }
      let self = this;
      self.form.content = self.$refs.editor.myValue;
      this.form.expireTime = this.SeTime[1];
      this.form.startTime = this.SeTime[0];
      console.log(this.durations);
      this.durations.forEach((item, i) => {
        if (item.SeTime) {
          this.durations[i].startTime = item.SeTime[0];
          this.durations[i].endTime = item.SeTime[1];
        } else {
          this.timeerr = true;
        }
      });
      if (this.timeerr) {
        this.$root.warn("政策起止时间不能为空！", "");
        return false;
      }
      this.form.durations = this.durations;

      if (self.form.title.length <= 0) {
        self.$root.warn("请输入标题", "");
        return;
      } else if (self.form.content.length <= 0) {
        self.$root.warn("请输入内容", "");
        return;
      }

      if (this.deftimes(this.form.expireTime, this.form.startTime) < 0) {
        self.$root.warn("修改失败，截至时间不能选在开始时间以前。");
        return false;
      } else {
        this.edit();
        this.$axios
          .put("/policy", this.form)
          .then((response) => {
            if (response.data.status === 200) {
              self.$root.success("保存成功");
              self.$router.push({ path: "/policylist" });
            } else {
              self.$root.warn("编辑失败", response.data.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    onChangePolicyDuration(id, startTime, endTime) {
      this.$axios
        .put("/policy/duration", {
          id: id,
          startTime: startTime,
          endTime: endTime,
        })
        .then((response) => {
          console.log(id);
          console.log(response);
          if (response.data.status === 200) {
            this.$root.success("保存成功");
            // this.$router.push({path: '/policylist'});
            this.getData();
          } else {
            this.$root.warn("保存失败", response.data.data);
          }
        });
    },

    edit() {
      for (let i = 0; i < this.durations.length; i++) {
        let id = this.durations[i].id;
        let startTime = this.durations[i].startTime;
        let endTime = this.durations[i].endTime;
        this.$axios
          .put("/policy/duration", {
            id: id,
            startTime: startTime,
            endTime: endTime,
          })
          .then((res) => {
            console.log(res);
          });
        console.log(startTime);
      }
    },
    /**
     *
     * @param endTime 截至日期
     * @returns {number}
     */
    deftime(endTime) {
      return this.$moment(endTime).diff(this.$moment(), "days");
    },
    /**
     *
     * @param ETime 截至时间
     * @param STime 开始时间
     * @returns {number}
     */
    deftimes(ETime, STime) {
      return this.$moment(ETime).diff(STime, "days");
    },
    requestCancel() {
      // this.$router.back();
      this.$router.replace({
        path: "/policylist",
        query: { page: this.$route.query.page },
      });
    },
  },
  components: {
    editor,
  },
  created() {
    this.getData();
    this.form.id = this.$route.query.id;
  },
};
</script>

<style scoped>
.el-form {
  margin: 32px 32px 0;
}
.text {
  color: #ffffff;
}
</style>
